import React from 'react';
import MigoLandingPage from './MigoLandingPage';

function App() {
  return (
    <div className="App">
      <MigoLandingPage />
    </div>
  );
}

export default App;